import React, { useState, useEffect } from "react";
import apiClient from "../services/api";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { API_CLIENT } from "../constants/api";

import UserTable from "../components/table/UserTable";
import AddUserForm from "../components/forms/AddUserForm";
import EditUserForm from "../components/forms/EditUserForm";

const Home = props => {
  const emptyUser = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "Fribourg",
    nbm_pneu: 0
  };
  const [users, setusers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentUser, setCurrentuser] = useState(emptyUser);

  const refreshUserTable = () => {
    apiClient
      .get(API_CLIENT, { crossdomain: true })
      .then(response => response.data)
      .then(data => {
        setusers(data.data);
      });
  };

  useEffect(() => {
    refreshUserTable();
  }, []);

  // API CALLS

  const addUser = user => {
    apiClient.post(API_CLIENT, user).then(res => {
      refreshUserTable();
    });
  };

  const deleteUser = id => {
    confirmAlert({
      title: "Supression du client",
      message:
        "Est-tu sûr de vouloir le supprimer ? Cela implique de perdre toutes ses factures",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            apiClient.delete(`${API_CLIENT}/${id}`).then(res => {
              refreshUserTable();
            });
          }
        },
        {
          label: "Non"
        }
      ]
    });
  };

  const updateUser = (id, user) => {
    apiClient.put(`${API_CLIENT}/${id}`, user).then(res => {
      refreshUserTable();
    });
    setCurrentuser(emptyUser);
    setEditing(false);
  };

  const searchClient = e => {
    if (e.target.value.length < 1) {
      refreshUserTable();
    } else if (e.target.value.length < 3) return;
    apiClient
      .get(`${API_CLIENT}/search/${e.target.value}`, { crossdomain: true })
      .then(response => response.data)
      .then(data => {
        setusers(data.data);
      });
  };

  // STATE VIEW MANAGMENT
  const editUser = user => {
    setCurrentuser(user);
    setEditing(true);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 l8">
          <h4>Clients</h4>

          <div className="input-field col s12">
            <i className="material-icons prefix">search</i>
            <input
              id="search"
              type="text"
              className="validate"
              onChange={searchClient}
            />
            <label htmlFor="search">Recherche</label>
          </div>

          <UserTable users={users} editRow={editUser} deleteUser={deleteUser} />
        </div>
        {editing ? (
          <div className="col s12 l4">
            <h4>Edition du client</h4>
            <EditUserForm
              setEditing={setEditing}
              currentUser={currentUser}
              updateUser={updateUser}
            />
          </div>
        ) : (
          <div className="col s12 l4">
            <h4>Nouveau Client</h4>
            <AddUserForm addUser={addUser} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
