import React from 'react';
import img from '../../img/RD-Automobile-SARL-white.png';

const NavBar = () => (
    <nav>
        <div className="navigator">
            <img className="logo"src={img}/>
            <a href="/">Home</a>
        </div>
    </nav>
);

export default NavBar;