import React, { useState } from "react";

const AddUserForm = props => {
  const initialFormState = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "Fribourg",
    nbr_pneu: 0,
    tel: ""
  };
  const [user, setUser] = useState(initialFormState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const submitForm = event => {
    event.preventDefault();
    if (!user.first_name || !user.last_name) return;
    props.addUser(user);
    setUser(initialFormState);
  };

  return (
    <div className="row">
      <form className="col s12" onSubmit={submitForm}>
        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={user.first_name}
              onChange={handleInputChange}
            />
            <label htmlFor="first_name" className="active">
              Prénom
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              name="last_name"
              value={user.last_name}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="last_name" className="active">
              Nom
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              name="address"
              value={user.address}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="address" className="active">
              Adresse
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              name="city"
              value={user.city}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="city" className="active">
              Ville
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              name="state"
              value={user.state}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="state" className="active">
              Canton
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              name="tel"
              value={user.tel}
              onChange={handleInputChange}
              
            />
            <label htmlFor="tel" className="active">
              N° de téléphone
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="number"
              name="nbr_pneu"
              value={user.nbr_pneu}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="nbr_pneu" className="active">
              Nombre de pneu
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <button className="waves-effect waves-light btn" type="submit">
              Créer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
