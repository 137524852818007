import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import apiClient from "../services/api";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { API_FACTURE } from "../constants/api";
import AddFactureForm from "../components/forms/AddFactureForm";
import FactureTable from "../components/table/FactureTable";
import EditFactureForm from "../components/forms/EditFactureForm";
/*

import EditFactureForm from "../components/forms/EditFactureForm";
*/

const Facture = props => {
  if (!props.location.state) {
    props.history.push("/");
    return null;
  }
  const emptyFacture = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "Fribourg",
    nbm_pneu: 0
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentFacture, setCurrentFacture] = useState(emptyFacture);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editing, setEditing] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [factures, setfactures] = useState([]);

  const refreshFactureTable = () => {
    const client = props.location.state.userid;
    apiClient
      .get(`${API_FACTURE}/${client}`, { crossdomain: true })
      .then(response => response.data)
      .then(data => {
        setfactures(data.data);
      });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refreshFactureTable();
  }, []);

  const addFacture = facture => {
    const client = props.location.state.userid;
    apiClient.post(`${API_FACTURE}/${client}`, facture).then(res => {
      refreshFactureTable();
    });
  };

  const deleteFacture = id => {
    const client = props.location.state.userid;
    confirmAlert({
      title: "Supression du client",
      message:
        "Est-tu sûr de vouloir supprimer cette facture ? Il sera impossible de la récupérer",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            apiClient.delete(`${API_FACTURE}/${client}/${id}`).then(res => {
              refreshFactureTable();
            });
          }
        },
        {
          label: "Non"
        }
      ]
    });
  };

  const updateFacture = facture => {
    const factid = facture.id;
    delete facture.id;
    const client = props.location.state.userid;
    apiClient.put(`${API_FACTURE}/${client}/${factid}`, facture).then(res => {
      refreshFactureTable();
    });
    setCurrentFacture(emptyFacture);
    setEditing(false);
  };

  // STATE VIEW MANAGMENT
  const editFacture = facture => {
    setCurrentFacture(facture);
    setEditing(true);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 l6">
          <h4>Factures</h4>
          <div>Search bar ici</div>
          <FactureTable
            factures={factures}
            editRow={editFacture}
            deleteFacture={deleteFacture}
            user={props.location.state.user}
          />
        </div>
        {editing ? (
          <div className="col s12 l6">
            <h4>Edition de la facture</h4>
            <EditFactureForm
              clientId={props.location.state.userid}
              currentFacture={currentFacture}
              setEditing={setEditing}
              updateFacture={updateFacture}
            />
          </div>
        ) : (
          <div className="col s12 l6">
            <h4>Nouvelle Facture</h4>
            <AddFactureForm
              addFacture={addFacture}
              clientId={props.location.state.userid}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Facture);
