import React from "react";
import FactureRenderer from "../pdf-render/facture_render";
const FactureTable = props => {
  return (
    <table className="responsive-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.factures.length > 0 ? (
          props.factures.sort((facture1, facture2) => {
              return Date.parse(facture2.date) - Date.parse(facture1.date);
          }).map(facture => (
            <tr key={facture.id}>
              <td>{facture.date}</td>
              <td>
                <div className="buttons-wrapper">
                    <button
                        className="waves-effect waves-light btn-small"
                        onClick={() => props.editRow(facture)}
                    >
                        Editer
                    </button>
                    <button
                        className="waves-effect waves-light btn-small red darken-4"
                        onClick={() => props.deleteFacture(facture.id)}
                    >
                        Supprimer
                    </button>
                    <FactureRenderer facture={facture} user={props.user} />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>{props.factures[0]} No Factures</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default FactureTable;
