import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from "@react-pdf/renderer";
import img from "../../img/RD-Automobile-SARL.png";
import Roboto from "../../style/Roboto-Regular.ttf"
import RobotoBold from "../../style/Roboto-Bold.ttf"

const logoPath = "../img/RD-Automobile-SARL.png";


Font.register({
    family: 'Roboto',
    format: "truetype",
    src: Roboto,
});

Font.register({
    family: 'Roboto-bold',
    format: "truetype",
    src: RobotoBold,
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        position: 'relative',
        backgroundColor: "#ffffff",
        color: '#252525',
        fontSize: 10,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 32,
        paddingBottom: 12,
        lineHeight: 1.4,
    },
    addressBox: {
        lineHeight: 1.4,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerLeft: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    headerRight: {
        flex: 1,
        textAlign: 'right',
    },
    mainPart: {
        display: 'flex',
        flexDirection: 'column',
    },
    infos: {
        display: 'flex',
        flexDirection: 'column',
        color: '#252525',
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column'
    },
    mt: {
        marginTop: 12
    },
    mt2: {
        marginTop: 24
    },
    mb: {
        marginBottom: 12
    },
    mb2: {
        marginBottom: 24
    },
    ml: {
        marginLeft: 12
    },
    ml2: {
        marginLeft: 24
    },
    mr: {
        marginRight: 12
    },
    mr2: {
        marginRight: 24
    },
    px: {
        paddingLeft: 2,
        paddingRight: 2
    },
    py: {
        paddingTop: 1,
        paddingBottom: 1
    },
});

const PdfDocument = props => {
    const facture = props.data;
    const client = props.client;

    let clientId = "28";
    let factureId = "#29948";
    /*for (let i = 0; i < 10; i++) {
        clientId = clientId + props.client.id.charCodeAt(i).toString();
        factureId = factureId + props.data.id.charCodeAt(i).toString();
    }*/

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={{position: 'absolute', top: -30, left: -250, height: '600px', opacity: .1, zIndex: 0}} source={img} />
                {/*Header part*/}
                <View style={{...styles.flexCol, justifyContent: 'space-between', height: '100%'}}>
                    <View style={{...styles.flexCol, justifyContent: 'flex-start'}}>
                        <View style={styles.header}>
                            <View style={{...styles.headerLeft, ...styles.addressBox}}>
                                <Text style={{fontFamily: 'Roboto-bold', fontSize: 11}}>RD Automobile Sàrl</Text>
                                <Text>Route des molleyres 9</Text>
                                <Text>1744 Chénens FR</Text>
                                <Text>0792043885</Text>
                                <Text>info@rdautomobile.ch</Text>
                                <Text>http://rdautomobile.ch/</Text>
                            </View>
                            <View style={styles.headerRight}>
                                <View style={styles.infoRow}>
                                    <Text style={{flex: 1}}>No de facture</Text>
                                    <Text style={{flex: 1}}>{factureId}</Text>
                                </View>
                                <View style={styles.infoRow}>
                                    <Text style={{flex: 1}}>Date du document:</Text>
                                    <Text style={{flex: 1}}>{new Date(
                                        facture.date
                                    ).toLocaleDateString("en-GB")}
                                    </Text>
                                </View>
                                <View style={styles.infoRow}>
                                    <Text style={{flex: 1}}>No de client</Text>
                                    <Text style={{flex: 1}}>{clientId}</Text>
                                </View>
                            </View>
                        </View>

                        {/*Main part*/}
                        <View style={{...styles.mainPart, ...styles.mt2}}>
                            {/* Address */}
                            <View style={{...styles.flexCol, flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', ...styles.addressBox}}>
                                <Text style={{fontFamily: 'Roboto-bold', fontSize: 12, ...styles.mb}}>Adresse de facturation</Text>
                                <Text>{client.adresse}</Text>
                                <Text>{`${client.first_name} ${client.last_name}`}</Text>
                                <Text>{client.address}</Text>
                                <Text>{client.city}</Text>
                                <Text>{client.state}</Text>
                            </View>
                            {/* Elements of facturation */}
                            <View style={{...styles.flexCol, flex: 1, justifyContent: 'flex-start', ...styles.mt2}}>
                                <Text style={{fontFamily: 'Roboto-bold', fontSize: 12, ...styles.mb}}>Prestations</Text>
                                {/* Table header */}
                                <View style={{...styles.flexRow, ...styles.px, ...styles.py, marginBottom: 2, flex: 1, fontSize: 11, fontFamily: 'Roboto-bold', borderBottom: '1px solid #cecece'}}>
                                    <View style={{flex: 1}}>
                                        <Text>Qte</Text>
                                    </View>
                                    <View style={{flex: 1}}>
                                        <Text>Description</Text>
                                    </View>
                                    <View style={{flex: 1, textAlign: 'right'}}>
                                        <Text>Prix unitaire</Text>
                                    </View>
                                    <View style={{flex: 1, textAlign: 'right'}}>
                                        <Text>Total (CHF)</Text>
                                    </View>
                                </View>
                                {facture.items.map((item, index) => (
                                    <View style={{...styles.flexRow, ...styles.px, ...styles.py, flex: 1, borderBottom: '1px solid #ececec'}}>
                                        <View style={{flex: 1}}>
                                            <Text>{item.nombre} {item.unit}</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text>{item.name}</Text>
                                        </View>
                                        <View style={{flex: 1, textAlign: 'right'}}>
                                            <Text>{item.prix_unit} CHF/{item.unit}</Text>
                                        </View>
                                        <View style={{flex: 1, textAlign: 'right'}}>
                                            <Text>{item.prix_total}</Text>
                                        </View>
                                    </View>
                                ))}
                                {/*Pricing*/}
                                <View style={{...styles.flexRow, flex: 1, ...styles.mt}}>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1}}>
                                        <View style={{flex: 1, textAlign: 'right'}}>
                                            <Text>TVA</Text>
                                        </View>
                                    </View>
                                    <View style={{flex: 1, textAlign: 'right'}}>
                                        <Text>{facture.tva} %</Text>
                                    </View>
                                </View>
                                <View style={{...styles.flexRow, flex: 1}}>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1, marginTop: 1, textAlign: 'right'}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{flex: 1, marginTop: 1, textAlign: 'right'}}>
                                        <Text>{facture.prix}</Text>
                                    </View>
                                </View>
                                {
                                    facture.remise === 0 ? <></> :
                                        <View style={{...styles.flexRow, flex: 1}}>
                                            <View style={{flex: 1}}/>
                                            <View style={{flex: 1}}/>
                                            <View style={{flex: 1, marginTop: 1, textAlign: 'right'}}>
                                                <Text>Remise</Text>
                                            </View>
                                            <View style={{flex: 1, marginTop: 1, textAlign: 'right'}}>
                                                <Text>{facture.remise}</Text>
                                            </View>
                                        </View>
                                }
                                <View style={{...styles.flexRow, flex: 1, marginTop: 1, fontSize: 11, fontFamily: 'Roboto-bold', borderTop: "1px solid #cecece"}}>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1}}/>
                                    <View style={{flex: 1, textAlign: 'right'}}>
                                        <Text>Total TTC</Text>
                                    </View>
                                    <View style={{flex: 1, textAlign: 'right'}}>
                                        <Text>{(Math.round(parseFloat(facture.prix_ttc)*2)/2).toFixed(2)}</Text>
                                    </View>
                                </View>
                            </View>
                            {/* Work done */}
                            <View style={{...styles.flexCol, flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', ...styles.mt2}}>
                                <Text style={{fontFamily: 'Roboto-bold', fontSize: 12, ...styles.mb}}>Travail effectué</Text>
                                {facture.service.map(item => (
                                    <Text>{item}</Text>
                                ))}
                            </View>

                            {/* Comments */}
                            {
                                <View style={{...styles.flexCol, flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', ...styles.mt2}}>
                                    <Text style={{fontFamily: 'Roboto-bold', fontSize: 12, ...styles.mb}}>Commentaires</Text>
                                    <Text>{facture.description}</Text>
                                </View>
                            }
                        </View>
                    </View>
                    {/*Footer part*/}
                    <View style={{...styles.flexRow, justifyContent: 'space-between', opacity: .4, fontSize: 8}}>
                        <Text style={styles.carInfo}>{facture.model} - {facture.marque} - {facture.km} km</Text>
                        <Text>No TVA {facture.no_tva}</Text>
                    </View>
                </View>
            </Page>
            {/*<Page style={styles.page}>
        <View style={styles.carInfoContainer}>
          <Image style={styles.image} source={img} />
          <Text style={styles.carInfo}>Marque : {facture.marque}</Text>
          <Text style={styles.carInfo}>Plaque : {facture.model}</Text>
          <Text style={styles.carInfo}>{facture.km} km</Text>
        </View>

        <View style={styles.itemContainer}>
          <View style={styles.garageInfoOne}>
            <Text style={styles.garageInfo}>RD Automobile Sàrl</Text>
          </View>
          <View style={styles.garageInfoOne}>
            <Text style={styles.garageInfo}>Route des molleyres 9</Text>
          </View>
          <View style={styles.garageInfoOne}>
            <Text style={styles.garageInfo}>1744 Chénens</Text>
          </View>
          <View style={styles.garageInfoOne}>
            <Text style={styles.garageInfo}>Fribourg</Text>
          </View>
        </View>
        <View style={styles.clientInfoContainer}>
          <Text
            style={styles.clientInfo}
          >{`${client.first_name} ${client.last_name}`}</Text>
          <Text style={styles.clientInfo}>{client.address}</Text>
          <Text style={styles.clientInfo}>{client.city}</Text>
          <Text style={styles.clientInfo}>{client.state}</Text>
        </View>
        <Text style={styles.details}>N° de facture : {factureId}</Text>
        <Text style={styles.details}>N° de client : {clientId}</Text>
        <Text style={styles.details}>N° de TVA : {facture.no_tva}</Text>
        <Text style={styles.details}>
          {`Date de facturation : ${new Date(
            facture.date
          ).toLocaleDateString()}`}
        </Text>
        <View style={styles.itemContainer}>
          <View style={styles.itemOneInfo}>
            <Text style={styles.itemInfoHeader}>Qte</Text>
            <Text style={styles.itemInfoHeader}>Nom</Text>
            <Text style={styles.itemInfoHeader}>Prix unitaire</Text>
            <Text style={styles.itemInfoHeader}>Total</Text>
          </View>
          {facture.items.map(item => (
            <View style={styles.itemOneInfo}>
              <Text style={styles.itemInfo}>
                {item.nombre} {item.unit}
              </Text>
              <Text style={styles.itemInfo}>{item.name}</Text>
              <Text style={styles.itemInfo}>
                {item.prix_unit} CHF/{item.unit}
              </Text>
              <Text style={styles.itemInfo}>{item.prix_total} CHF</Text>
            </View>
          ))}
        </View>
        <Text style={styles.details}>
          Facturation sous {facture.delai} jours
        </Text>
        <View style={styles.itemContainer}>
          <View style={styles.itemOneInfo}>
            <Text style={styles.itemInfoHeader}>Travail Effectué</Text>
          </View>
          {facture.service.map(item => (
            <View style={styles.itemOneInfo}>
              <Text style={styles.itemInfo}>- {item}</Text>
            </View>
          ))}
        </View>
        <View style={styles.descContainer}>
          <Text style={styles.itemInfoHeader}>Détails supplémentaire</Text>
          <Text style={styles.priceItem}>{facture.description}</Text>
        </View>
        <View style={styles.priceContainer}>
          <Text style={styles.priceItem}>
            {facture.remise === "0" ? "" : `Remise : ${facture.remise} CHF`}
          </Text>
        </View>
        <View style={styles.priceContainer}>
          <Text style={styles.priceItem}>Prix : {facture.prix} CHF</Text>
          <Text style={styles.priceItem}>TVA : {facture.tva} %</Text>
          <Text style={styles.priceItem}>
            Prix TTC : {parseFloat(facture.prix_ttc).toFixed(2)} CHF
          </Text>
        </View>
      </Page>*/}
        </Document>
    );
};

export default PdfDocument;
