import React, {useState} from "react";
import PdfDocument from "./facture_generator";
import Modal from 'react-modal';
import { PDFViewer } from "@react-pdf/renderer";

// Create Document Component
const FactureRenderer = ({facture, user}) => {
    const [modal, setOpen] = useState(false);
  return (
      <>
          <Modal
              isOpen={modal}
              contentLabel="Minimal Modal Example"
          >
              <button onClick={() => setOpen(false)}>Fermer</button>
              <PDFViewer>
                  <PdfDocument data={facture} client={user} />
              </PDFViewer>
          </Modal>
          <div className="buttons-wrapper">
              <button
                  className="waves-effect waves-light btn-small darken-4"
                  onClick={() => setOpen(true)}
              >
                  Visualiser
              </button>
          </div>
      </>
  );
};

export default FactureRenderer;
