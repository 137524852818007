import React, { useState } from "react";
import Creatable from "react-select/creatable";

const EditFactureForm = (props) => {
  const initOneItem = {
    name: "",
    prix_unit: 0,
    nombre: 0,
    prix_total: 0,
    unit: "L",
    tvaDejaIncl: false,
  };

  const servicesOptions = props.currentFacture.service.map((service) => ({
    value: service,
    label: service,
  }));

  const [facture, setFacture] = useState(props.currentFacture);
  const [items, setItems] = useState(props.currentFacture.items);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFacture({ ...facture, [name]: value });
  };

  const calculateNewPrice = (n_tva, n_remise, n_items) => {
    
    const prixTvaDejaIncl =
      n_items.length > 0
        ? n_items
            .map((item) => (item.tvaDejaIncl ? parseFloat(item.prix_total) : 0))
            .reduce((a, b) => a + b) - parseFloat(n_remise)
        : 0;
    const prixTvaPasIncl =
      n_items.length > 0
        ? n_items
            .map((item) =>
              !item.tvaDejaIncl ? parseFloat(item.prix_total) : 0
            )
            .reduce((a, b) => a + b) - parseFloat(n_remise)
        : 0;
    const prixfinal = prixTvaPasIncl + prixTvaDejaIncl;
    let prixfinalttc = prixfinal + (prixTvaPasIncl * parseFloat(n_tva)) / 100;
    setFacture({
      ...facture,
      ["prix"]: prixfinal,
      ["prix_ttc"]: prixfinalttc,
      ["tva"]: n_tva,
      ["remise"]: n_remise,
    });
  };

  const updatePrice = (event) => {
    const { name, value } = event.target;
    let n_tva = facture.tva;
    let n_remise = facture.remise;
    let n_items = items;
    switch (name) {
      case "tva":
        n_tva = value;
        break;
      case "items":
        n_items = value;
        break;
      case "remise":
        n_remise = value;
        break;
    }
    calculateNewPrice(n_tva, n_remise, n_items);
  };

  const addFielditem = () => {
    const tmpItems = items;
    tmpItems.push(initOneItem);
    setItems(tmpItems);
    calculateNewPrice(facture.tva, facture.remise, tmpItems);
  };

  const removeFielditem = (i) => {
    const tmpItems = items;
    tmpItems.splice(i, 1);
    setItems(tmpItems);
    calculateNewPrice(facture.tva, facture.remise, tmpItems);
  };

  const updateItem = (i, name, value) => {
    let newItems = items;
    newItems[i][name] = value;
    newItems[i].prix_total = (
      parseFloat(newItems[i].nombre) * parseFloat(newItems[i].prix_unit)
    ).toFixed(2);
    setItems(newItems);
    calculateNewPrice(facture.tva, facture.remise, newItems);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (!facture.prix || !facture.prix_ttc) return;
    const newFacture = facture;
    if (items.length > 0) {
      newFacture.items = items;
    }
    props.updateFacture(newFacture);
  };

  const onSelectService = (e) => {
    const newFacture = facture;
    newFacture.service = e ? e.map((service) => service.value) : [];
    setFacture(newFacture);
  };

  return (
    <div className="row edit-bill-wrapper">
      <form className="col s12" onSubmit={submitForm}>
        <div className="row">
          <div className="input-field col s12">
            <Creatable
              defaultValue={servicesOptions}
              isMulti
              isSearchable
              onChange={onSelectService}
              options={servicesOptions}
            />
            <label htmlFor="service" className="active">
              Service
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s6">
            <select
              className="selector"
              id="delai"
              name="delai"
              onChange={handleInputChange}
              value={facture.delai}
            >
              <option value={30}>30 jours</option>
              <option value={15}>15 jours</option>
            </select>
            <label htmlFor="delai" className="active">
              Delai
            </label>
          </div>

          <div className="input-field col s6">
            <input
              type="text"
              id="marque"
              name="marque"
              value={facture.marque}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="marque" className="active">
              Marque
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s6">
            <input
              type="text"
              id="model"
              name="model"
              value={facture.model}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="model" className="active">
              N° de plaque
            </label>
          </div>

          <div className="input-field col s6">
            <input
              type="text"
              id="km"
              name="km"
              value={facture.km}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="km" className="active">
              Km
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              type="date"
              id="date"
              name="date"
              value={facture.date}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="date" className="active">
              Date de la facture
            </label>
          </div>
        </div>

        <div className="divider" />
        {items.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <div className="row itemsForm">
                <div className="input-field col s12 m6">
                  <input
                    type="text"
                    id={`item_${i}_name`}
                    name="name"
                    value={items[i].name}
                    onChange={(event) =>
                      updateItem(i, event.target.name, event.target.value)
                    }
                    required
                  />
                  <label htmlFor={`item_${i}_name`} className="active">
                    Nom
                  </label>
                </div>

                <div className="input-field col s6 m3">
                  <input
                    type="number"
                    id={`item_${i}_nbr`}
                    name="nombre"
                    value={items[i].nombre}
                    onChange={(event) =>
                      updateItem(i, event.target.name, event.target.value)
                    }
                    required
                  />
                  <label htmlFor={`item_${i}_nbr`} className="active">
                    Combien
                  </label>
                </div>

                <div className="input-field col s6 m3">
                  <input
                    type="text"
                    id={`item_${i}_unit`}
                    name="unit"
                    value={items[i].unit}
                    onChange={(event) =>
                      updateItem(i, event.target.name, event.target.value)
                    }
                    required
                  />
                  <label htmlFor={`item_${i}_unit`} className="active">
                    Unité
                  </label>
                </div>

                <div className="input-field col s5 m3">
                  <input
                    type="number"
                    id={`item_${i}_prix_unit`}
                    name="prix_unit"
                    value={items[i].prix_unit}
                    onChange={(event) =>
                      updateItem(i, event.target.name, event.target.value)
                    }
                    required
                  />
                  <label htmlFor={`item_${i}_prix_unit`} className="active">
                    Prix unitaire (CHF)
                  </label>
                </div>

                <div className="input-field col s3">
                  <input
                    type="number"
                    id={`item_${i}_prix`}
                    name="prix_total"
                    value={items[i].prix_total}
                    onChange={(event) =>
                      updateItem(i, event.target.name, event.target.value)
                    }
                    disabled
                  />
                  <label htmlFor={`item_${i}_prix`} className="active">
                    total (CHF)
                  </label>
                </div>

                <div className="col s3">
                  <p>
                    <label>
                      TVA déjà incl
                      <input
                        type="checkbox"
                        class="filled-in"
                        name="tvaDejaIncl"
                        defaultChecked={items[i].tvaDejaIncl}
                        onChange={(event) =>
                          updateItem(i, event.target.name, event.target.checked)
                        }
                      />
                      <span />
                    </label>
                  </p>
                </div>

                <div className="input-field col s3">
                  <a className="btn-floating btn-large waves-effect waves-light red">
                    <i
                      className="material-icons"
                      onClick={() => removeFielditem(i)}
                    >
                      remove
                    </i>
                  </a>
                </div>
              </div>
              <div className="divider" />
            </React.Fragment>
          );
        })}
        <div className="row">
          <div className="input-field col s12">
            <a className="btn-floating btn-large waves-effect waves-light ">
              <i className="material-icons" onClick={addFielditem}>
                add
              </i>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <textarea
              id="description"
              name="description"
              value={facture.description}
              onChange={handleInputChange}
              className="materialize-textarea"
            />
            <label htmlFor="description" className="active">
              Description
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12 m6">
            <input
              type="number"
              id="remise"
              name="remise"
              value={facture.remise}
              onChange={updatePrice}
            />
            <label htmlFor="remise" className="active">
              Remise (CHF)
            </label>
          </div>

          <div className="input-field col s12 m6">
            <input
              type="text"
              name="tva"
              value={facture.tva}
              onChange={updatePrice}
              required
            />
            <label htmlFor="tva" className="active">
              TVA (%)
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              type="text"
              id="no_tva"
              name="no_tva"
              value={facture.no_tva}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="marque" className="active">
              N° de TVA
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <input
              type="number"
              id="prix"
              name="prix"
              value={facture.prix}
              disabled
            />
            <label htmlFor="first_name" className="active">
              Prix hors taxes
            </label>
          </div>

          <div className="input-field col s12 m6 disabled">
            <input
              type="number"
              id="prix_ttc"
              name="prix_ttc"
              value={facture.prix_ttc}
              disabled
            />
            <label htmlFor="prix_ttc" className="active">
              Prix TTC
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12 m6">
            <button className="waves-effect waves-light btn">
              Enregistrer
            </button>
          </div>
          <div className="input-field col s12 m6">
            <button
              className="waves-effect waves-light btn"
              onClick={() => props.setEditing(false)}
            >
              Retour
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditFactureForm;
