import React from "react";

import NavBar from "./components/navbar";
import { Switch, Route, withRouter } from "react-router-dom";

import Home from "./pages/Home";
import Facture from "./pages/Facture";

// Style
import "./style/main.scss";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const App = () => {
  return (
    <React.Fragment>
      <NavBar />
      <Switch>
        <Route exact path="/" render={() => <Home/>} />
        <Route path="/facture" render={() => <Facture/>} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(App);
