import React from "react";
import { Link } from "react-router-dom";

const UserTable = props => (
  <table className="responsive-table">
    <thead>
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {props.users.length > 0 ? (
        props.users.map(user => (
          <tr key={user.id}>
            <td>{user.last_name}</td>
            <td>{user.first_name}</td>
            <td>
             <div className="buttons-wrapper">
                 <button
                     className="waves-effect waves-light btn-small"
                     onClick={() => props.editRow(user)}
                 >
                     Editer
                 </button>
                 <div />
                 <button
                     className="waves-effect waves-light btn-small red darken-4"
                     onClick={() => props.deleteUser(user.id)}
                 >
                     Supprimer
                 </button>
                 <div />
                 <Link to={{ pathname: "/facture", state: { userid: user.id, user: user } }}>
                     <button className="waves-effect waves-light btn-small">
                         Gèrer factures
                     </button>
                 </Link>
             </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={3}>{props.users[0]} No Users</td>
        </tr>
      )}
    </tbody>
  </table>
);

export default UserTable;
